@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn {
        @apply rounded-md disabled:opacity-50 disabled:cursor-not-allowed px-4 py-2 text-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 relative inline-block font-medium transition-colors duration-100 border cursor-pointer select-none;
        @apply btn-default;
    }

    .btn.btn-xs {
        @apply px-3 py-2 leading-4 text-sm;
    }
    .btn.btn-sm {
        @apply px-3 py-2 leading-4 text-sm;
    }
    .btn.btn-md {
        @apply px-5 py-2 text-base;
    }
    .btn.btn-lg {
        @apply px-5 py-3 text-base;
    }
    .btn.btn-xl {
        @apply px-6 py-4 text-base;
    }

    .btn-default {
        @apply border-gray-300 bg-white disabled:hover:bg-white hover:bg-gray-100 focus-visible:ring-primary-500 text-gray-600;
    }
    .btn.btn-primary {
        @apply border-transparent bg-primary-600 disabled:hover:bg-primary-500 hover:bg-primary-700 focus-visible:ring-primary-500 text-white;
    }
    .btn.btn-secondary {
        @apply border-transparent bg-primary-100 disabled:hover:bg-primary-100 hover:bg-primary-200 focus-visible:ring-primary-500 text-primary-800;
    }
    .btn.btn-secondary-outline {
        @apply border-primary-400 bg-primary-100 disabled:hover:bg-primary-100 hover:bg-primary-200 focus-visible:ring-primary-500 text-primary-800;
    }
    .btn.btn-gray {
        @apply border-transparent bg-gray-100 disabled:hover:bg-gray-50 hover:bg-gray-100 focus-visible:ring-gray-300 text-gray-700;
    }
    .btn.btn-gray-outline {
        @apply border-gray-200 bg-gray-100 disabled:hover:bg-gray-50 hover:bg-gray-50 focus-visible:ring-gray-300 text-gray-700;
    }
    .btn.btn-beige {
        @apply border-transparent bg-beige-700 disabled:hover:bg-beige-400 hover:bg-beige-800 focus-visible:ring-beige-600 text-white;
    }
    .btn.btn-beige-outline {
        @apply border-beige-500 bg-beige-100 disabled:hover:bg-beige-100 hover:bg-beige-200 focus-visible:ring-beige-500 text-beige-700;
    }
    .btn.btn-steel {
        @apply border-transparent bg-steel-200 disabled:hover:bg-steel-100 hover:bg-steel-100 focus-visible:ring-steel-500 text-steel-700;
    }
    .btn.btn-steel-outline {
        @apply border-steel-400 bg-steel-200 disabled:hover:bg-steel-100 hover:bg-steel-100 focus-visible:ring-steel-500 text-steel-700;
    }
    .btn.btn-white {
        @apply border-transparent bg-white disabled:hover:bg-white hover:bg-gray-100 focus-visible:ring-primary-500 text-gray-600;
    }
    .btn.btn-white-outline {
        @apply btn-default;
    }
    .btn.btn-white-outline-primary {
        @apply border-primary-400 bg-white disabled:hover:bg-white hover:bg-primary-100 focus-visible:ring-primary-500 text-primary-600;
    }
    .btn.btn-black {
        @apply border-transparent bg-gray-900 disabled:hover:bg-gray-900 hover:bg-gray-800 focus-visible:ring-gray-900 text-white;
    }
    .btn.btn-danger {
        @apply border-transparent bg-red-600 disabled:hover:bg-red-600 hover:bg-red-700 focus-visible:ring-red-300 text-white;
    }
    .btn.btn-warning {
        @apply border-transparent bg-yellow-500 disabled:hover:bg-yellow-500 hover:bg-yellow-600 focus-visible:ring-yellow-300 text-yellow-900;
    }

    .h0 {
        @apply text-gray-900 text-5xl md:text-6xl font-display font-bold leading-[0.9em] tracking-[-0.01em];
    }

    .h1 {
        @apply text-gray-900 text-4xl font-display font-semibold leading-9 tracking-[-0.01em];
    }

    .h2 {
        @apply text-gray-900 text-3xl font-display font-semibold leading-8 tracking-[-0.01em];
    }

    .h3 {
        @apply text-gray-900 text-xl font-display font-semibold leading-6 tracking-[-0.01em];
    }

    .heading {
        @apply font-semibold leading-tight text-primary-600;
    }

    .p-sm {
        @apply text-base text-gray-500 font-normal;
    }

    .p-md {
        @apply text-base md:text-lg text-gray-500 font-normal;
    }

    .p-lg {
        @apply text-xl text-gray-500 font-normal;
    }

    .media-outline {
        @apply shadow-xl rounded-xl ring-1 ring-black ring-opacity-5;
    }
}

[x-cloak] {
    display: none;
}

body .swiper-pagination {
    @apply static;
}

body .swiper-pagination-bullet {
    @apply h-2 w-2 inline-block rounded-full bg-gray-500;
}

body .swiper-pagination-bullet-active {
    @apply bg-primary-600;
}

.anchor {
    @apply relative md:-top-[90px] -top-[60px] invisible block;
}

.swiper-horizontal .swiper-button-next,
.swiper-horizontal .swiper-button-prev {
    @apply text-gray-600 bg-white rounded-full h-10 w-10 shadow-lg hover:bg-gray-100;
}

.swiper-horizontal .swiper-button-next {
    @apply right-4;
}

.swiper-horizontal .swiper-button-prev {
    @apply left-4;
}

.swiper-horizontal .swiper-button-next:after,
.swiper-horizontal .swiper-button-prev:after {
    @apply text-lg;
}

body lite-youtube:before {
    height: 100px;
}
